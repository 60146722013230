<template>
  <CRow>
    <CCol col="12">
      <ACard title="Gmail authentication">
        <CCardBody>
          <div class="mb-3">
            <span v-if="auth.user">
              {{ $t('User authenticated') }}: <b>{{ auth.user }}</b>
            </span>
            <span v-else>
              {{ $t('User is not authenticated') }}
            </span>
          </div>

          <CButton v-if="auth.user" class="mr-1" color="danger" @click="getData('logout')">{{ $t('Logout') }}</CButton>
          <CButton color="info" @click="redirect">{{ $t('Request new token') }}</CButton>
        </CCardBody>
      </ACard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'GMailToken',
  data() {
    return {
      auth: {}
    }
  },
  created() {
    this.getData(false)
  },
  methods: {
    redirect() {
      console.log(this.auth.routes.login)
      location.href = this.auth.routes.login
    },
    getData(type) {
      const self = this
      self.$http
        .get('admin/gmail/token' + (type ? `?${type}=true` : ''))
        .then(response => {
          self.auth = response.data.auth
          console.log(self.auth)

          if (type) {
            self.getData(false)
          }
        })
        .catch(error => {
          console.error(error)
          self.showAlert('There was an error')
        })
    }
  }
}
</script>
